/**
 * A utility to check if the browser supports everything
 * we want it to support. By using `<script type="module">
 * we have already established a baseline.
 *
 * However, this includes some early versions of MS Edge that
 * we don't want to support, so this check filters so that only
 * Chromium-based Edge is allowed. It will bump the limit for
 * Chrome and Firefox a little bit, but not to a degree that is
 * noticeable in statistics.
 *
 * We also check for rel="preload" support on links, which filters
 * out Safari 10. The main reason is to allow object spread syntax:
 * something that is widely used in the codebase outside of client-side-only
 * JS.
 *
 * @returns {boolean}
 */

function preloadSupported() {
  // https://caniuse.com/link-rel-preload
  const relList = document.createElement("link").relList;
  return !!(relList && relList.supports && relList.supports("preload"));
}

function reducedMotionSupported() {
  // https://caniuse.com/prefers-reduced-motion
  return window.matchMedia(
    "only all and (prefers-reduced-motion: no-preference), only all and (prefers-reduced-motion: reduce)"
  ).matches;
}

export const isModernEnough = () =>
  reducedMotionSupported() && preloadSupported();
