// @ts-check
import HTMLParsedElement from "../html-parsed-element.js";

const TAG_NAME = "gen-slideshow";

class Slideshow extends HTMLParsedElement {
  constructor() {
    super();
    this.visibleSlides = new Set();
    this.slidesHaveShifted = false;
  }
  parsedCallback() {
    this.init();
  }

  init() {
    const template = this.querySelector("template");
    if (!template) {
      throw new Error("No template found");
    }
    this.prepend(template.content.cloneNode(true));
    this.prevButton = this.querySelector(".slideshow__button-prev");
    this.nextButton = this.querySelector(".slideshow__button-next");
    this.liveRegion = this.querySelector(".slideshow__announcements");
    this.slidesContainer =
          /** @type {HTMLElement | null} */
          (this.querySelector(".slideshow__items"));
    if (!this.slidesContainer) {
      throw new Error("No slides container found");
    }
    this.slides = this.slidesContainer.querySelectorAll(".slideshow__item");
    const observer = new IntersectionObserver(
      this.handleSlideVisibility.bind(this),
      { root: this.slidesContainer, threshold: 0.1 }
    );

    this.slides.forEach((slide) => observer.observe(slide));
    this.addEventListener("click", this.handleClick.bind(this));
    this.addEventListener("slidechange", this.handleSlideChange.bind(this));
    this.classList.add("slideshow--active");
  }

  prevSlide() {
    if (!this.slidesContainer) {
      return;
    }
    this.slidesContainer.scrollLeft -= this.slidesContainer.offsetWidth;
  }

  nextSlide() {
    if (!this.slidesContainer) {
      return;
    }
    this.slidesContainer.scrollLeft += this.slidesContainer.offsetWidth;
  }

  /**
   * Handles events telling the slideshow to change slide.
   *
   * @param {Event} event
   */
  handleSlideChange(event) {
    if (!(this.slides && event instanceof CustomEvent)) {
      return;
    }
    const { index } = event.detail;
    // Already visible, do nothing.
    if (this.visibleSlides.has(this.slides[index])) {
      return;
    }
    const slide = this.slides[index];
    slide.scrollIntoView();
  }

  /**
   * Tracks visibility of slides.
   *
   * @param {IntersectionObserverEntry[]} entries
   */
  handleSlideVisibility(entries) {
    const [ entry ] = entries;
    const { target, isIntersecting } = entry;
    if (isIntersecting) {
      this.visibleSlides.add(target);
    } else {
      this.visibleSlides.delete(target);
    }
    this.updateSlideStates();
    this.updateButtonStates();
  }

  updateButtonStates() {
    if (!(this.slidesContainer && this.slides && this.slides.length > 0)) {
      return;
    }
    const firstSlide = this.slides[0];
    if (this.prevButton) {
      const isFirst = this.visibleSlides.has(firstSlide);
      this.prevButton.setAttribute("aria-disabled", isFirst ? "true" : "false");
      if (isFirst) {
        this.prevButton.setAttribute("tabindex", "-1");
      } else {
        this.prevButton.removeAttribute("tabindex");
      }
    }
    const lastSlide = this.slides[this.slides.length - 1];
    const isLast = this.visibleSlides.has(lastSlide);
    if (this.nextButton) {
      this.nextButton.setAttribute("aria-disabled", isLast ? "true" : "false");
      if (isLast) {
        this.nextButton.setAttribute("tabindex", "-1");
      } else {
        this.nextButton.removeAttribute("tabindex");
      }
    }
  }

  /**
   * Helps update _all_ slide states, even if just one slide
   * changes visibility, like the initial load state.
   */
  updateSlideStates() {
    if (!(this.slidesContainer && this.slides && this.slides.length > 0)) {
      return;
    }
    this.slides.forEach((slide) => {
      if (this.visibleSlides.has(slide)) {
        slide.classList.add("slideshow__item--visible");
        slide.removeAttribute("inert");
        slide.removeAttribute("aria-hidden");
        if (this.slidesHaveShifted) {
          this.setLiveRegionText(slide.getAttribute("aria-label") || "");
        }
      } else {
        slide.classList.remove("slideshow__item--visible");
        slide.setAttribute("inert", "");
        // Seems to be needed on Safari, where it would otherwise
        // go back to previous slide sometimes, causing it to be
        // scrolled into view and thus unhidden/announced.
        slide.setAttribute("aria-hidden", "true");
      }
    });
    // Note: this intentionally skips first render,
    // (when the first slide is made visible) so we don't
    // unnecessarily announce stuff on load.
    this.slidesHaveShifted = true;
  }

  /**
   * Handles click events.
   *
   * @param {MouseEvent} event
   */
  handleClick(event) {
    if (event.type !== "click" || !(event.target instanceof Element)) {
      return;
    }
    const { target } = event;
    const button = target && target.closest(".slideshow__button");
    if (!button) {
      return;
    }

    if (button.matches(".slideshow__button-next")) {
      this.nextSlide();
    } else if (button.matches(".slideshow__button-prev")) {
      this.prevSlide();
    }
  }

  /**
   * @param {string} text
   *
   * Set live region text for screenreaders
   */
  setLiveRegionText(text) {
    if (!(this.liveRegion)) {
      return;
    }
    this.liveRegion.textContent = text;
  }
}

export {
  TAG_NAME,
  Slideshow,
};
