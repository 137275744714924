/**
 * @param {{ path: string, size: number, filter?: Record<string, any> }} param
 * @returns {Promise<{ path: string, id: string }[] | undefined>}
 */
const getRecommendations = async ({ path, size, filter = {} }) => {
  const searchParams = new URLSearchParams({
    path,
    size,
    filter: JSON.stringify(filter),
  });

  const response = await fetch(`/api/recommendations/?${searchParams.toString()}`);

  if (response.ok) {
    return await response.json();
  }
};

export default getRecommendations;
