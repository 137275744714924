import { uuidv4 } from "../../helpers/uuid.js";

export const TAG_NAME = "gen-collapsible";

/**
 * Inspired/borrowed from ARIA Collapsible https://github.com/jgarber623/aria-collapsible/blob/main/aria-collapsible.js
 */
export class Collapsible extends HTMLElement {
  constructor() {
    super();
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleIfShouldClose = this.handleIfShouldClose.bind(this);
  }

  toggle() {
    this.control.setAttribute("aria-expanded", this.open);
    this.region.toggleAttribute("hidden", !this.open);
    this.dispatchEvent(new Event("toggle", {
      bubbles: true,
      cancelable: true,
    }));
  }

  static get observedAttributes() {
    return [ "open" ];
  }

  get open() {
    return Boolean(this._open);
  }

  set open(value) {
    this.toggleAttribute("open", Boolean(value));
  }

  attributeChangedCallback(name) {
    if (!this.constructor.observedAttributes.includes(name)) return;

    this._open = this.hasAttribute("open");
    this.toggle();
    this.toggleVisibilityElements.forEach((element) => element.toggleAttribute("hidden"));
  }

  connectedCallback() {
    this.control = this.querySelector("[data-control]");
    this.toggleVisibilityElements = this.control.querySelectorAll("[data-toggle-visibility]");
    this.region = this.querySelector("[data-region]");
    this.closeOnBlur = this.hasAttribute("close-on-blur");

    this.configured = this.control && this.region;

    if (!this.configured) return;

    this.control.addEventListener("click", this.handleOnClick);

    if (this.closeOnBlur) {
      addEventListener("focus", this.handleIfShouldClose, true);
    }

    const regionId = this.region.id || `region-${uuidv4()}`;

    this.region.setAttribute("id", regionId);
    this.control.setAttribute("aria-controls", regionId);
    this.control.removeAttribute("hidden");

    this.toggle();
  }

  disconnectedCallback() {
    if (!this.configured) return;

    if (this.closeOnBlur) {
      removeEventListener("focus", this.handleIfShouldClose, true);
    }

    this.control.removeEventListener("click", this.handleOnClick);
    this.control.removeAttribute("aria-controls");
    this.control.setAttribute("hidden", true);

    this.toggle();
  }

  handleOnClick(event) {
    event.preventDefault();
    this.open = !this.open;

    if (this.open) {
      /*
        This is needed due to the second click event will propagate
        thus the handleIfShouldClose() will be fired at the same time as the first click event happen
      */
      if (!this.toggleMenu) {
        event.stopPropagation();
      }

      addEventListener("click", this.handleIfShouldClose);
    } else {
      removeEventListener("click", this.handleIfShouldClose);
    }

    this.toggleMenu = !this.toggleMenu;
  }

  // Check if the menu got clicked outside or got focused out of it's own continer
  handleIfShouldClose(event) {
    if (
      this.open &&
      this.closeOnBlur &&
      event.target instanceof Node &&
      !this.region.contains(event.target)
    ) {
      event.preventDefault();
      this.open = false;
    }

    this.toggleMenu = false;
  }
}
