// @ts-check
window.didomiConfig = window.didomiConfig || {};
window.didomiOnReady = window.didomiOnReady || [];
window.didomiEventListeners = window.didomiEventListeners || [];

export const CUSTOM_PURPOSES = {
  necessary: "nodvandiga-b3JJQXqQ",
  functional: "funktionel-t2meYCeq",
  analysisAndDevelopment: "analysutv-BcCq4VEq",
  marketingAndContent: "marknadsfo-G6hNqgqm",
  marketingOtherPlatforms: "cookiesfo-EDDUixVd",
};

/** @typedef {import('../../lib/@types/didomi-types.js').IDidomiObject} Didomi */
/** @typedef {Array<(Didomi: Didomi) => any>} DidomiCallbacks */
/** @typedef {Array<{ event: string, listener: (context: any) => any }>} DidomiEvents */

export const initDidomiPreferences = () => {
  window.addEventListener("click", (e) => {
    if (e.target instanceof HTMLElement &&
        e.target.hasAttribute("data-didomi-preftoggle")) {
      /** @type {DidomiCallbacks} */(window.didomiOnReady).push((Didomi) => {
        Didomi.notice.show();
      });
    }
  });
};

/**
 * @param {(context: any) => any} callback
 * @returns {Promise<*>}
 */
const resolveConsent = (callback) => {
  return new Promise((resolve) => {
    if (!window.generika.didomiEnabled) {
      return resolve(true);
    }
    /** @type {DidomiCallbacks} */(window.didomiOnReady).push((Didomi) => resolve(callback(Didomi)));
  });
};

/**
 * @param {string} purpose
 * @returns {ReturnType<resolveConsent>}
 */
export const consentForPurpose = (purpose) => {
  return resolveConsent(
    (/** @type {Didomi} */Didomi) => Didomi.getUserConsentStatusForPurpose(purpose)
  );
};

/**
 * @param {string} vendor
 * @returns {ReturnType<resolveConsent>}
 */
export const consentForVendor = (vendor) => {
  return resolveConsent(
    (/** @type {Didomi} */Didomi) => Didomi.getUserConsentStatusForVendor(vendor)
  );
};

/**
 * @param {(context: any) => any} callback
 */
export const consentChanged = (callback) => {
  /** @type {DidomiEvents} */(window.didomiEventListeners).push({
    event: "consent.changed",
    listener: callback,
  });
};

/**
 * Subscribe a callback to the observable updating
 * when consent for a specific vendor has changed.
 *
 * @param {string|number} vendor
 * @param {(consent: boolean) => any} callback
 */
export const subscribeVendorConsent = (vendor, callback) => {
  if (!window.generika.didomiEnabled) {
    callback(true);
  }
  /** @type {DidomiCallbacks} */(window.didomiOnReady).push((Didomi) => {
    Didomi.getObservableOnUserConsentStatusForVendor(vendor)
      .subscribe(callback);
  });
};
