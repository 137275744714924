import resolvePartialContentAPI from "../../api/resolve-partial-content.js";
import recommendationsAPI from "../../api/recommendations.js";

export const TAG_NAME = "gen-load-more-articles";

export class LoadMoreArticles extends HTMLElement {
  constructor() {
    super();

    this.initObserver = this.initObserver.bind(this);
    this.handleOnObserve = this.handleOnObserve.bind(this);
  }

  initProps() {
    this.props = {
      id: this.getAttribute("id") || null,
      path: this.getAttribute("path") || null,
      size: Number(this.getAttribute("size")) || 10,
      margin: this.getAttribute("margin") || "100px",
      threshold: Number(this.getAttribute("threshold")) || 1.0,
    };

    if (!this.props.id) {
      throw new Error(`${TAG_NAME} | missing required "id" prop`);
    }

    if (!this.props.path) {
      throw new Error(`${TAG_NAME} | missing required "path" prop`);
    }
  }

  initState() {
    this.state = {
      isLoading: false,
      loadedRecommendations: false,
      recommendationItems: null,
      nextRecommendation: null,
    };
  }

  initElements() {
    const trigger = document.createElement("div");
    trigger.setAttribute("id", `${TAG_NAME}-trigger`);

    const loading = document.createElement("div");
    loading.setAttribute("id", `${TAG_NAME}-loading`);
    loading.classList.add("spinner");

    this.elements = {
      trigger,
      loading,
    };

    this.appendChild(this.elements.trigger);
  }

  initObserver() {
    this.observer = new IntersectionObserver(this.handleOnObserve, {
      rootMargin: this.props.margin,
      threshold: this.props.threshold,
    });

    this.observer.observe(this.elements.trigger);
  }

  connectedCallback() {
    this.initProps();
    this.initState();

    if (this.props.id && this.props.path) {
      this.initElements();
      this.initObserver();
    }
  }

  async handleOnObserve(entries) {
    const entry = entries[0];

    if (!(entry instanceof IntersectionObserverEntry)) {
      return;
    }

    if (
      entry.isIntersecting &&
      !this.state.isLoading
    ) {
      if (!this.state.loadedRecommendations) {
        await this.handleRecommendations();
      }

      if (this.state.nextRecommendation) {
        this.loadMore();
      }
    }
  }

  async handleRecommendations() {
    this.state.isLoading = true;

    const results = await recommendationsAPI({
      size: this.props.size,
      path: this.props.path,
      filter: {
        noneOf: [
          { ids: { values: [ this.props.id ] } },
        ],
      },
    });

    if (Array.isArray(results) && results.length) {
      this.state.recommendationItems = results;
      this.state.nextRecommendation = this.state.recommendationItems.shift();
    }

    this.state.loadedRecommendations = true;
    this.state.isLoading = false;
  }

  async loadMore() {
    this.state.isLoading = true;

    this.appendChild(this.elements.loading);

    try {
      const html = await resolvePartialContentAPI({ path: this.state.nextRecommendation.path });

      if (html) {
        const newContent = document
          .createRange()
          .createContextualFragment(html);

        this.insertBefore(newContent, this.elements.trigger);

        this.state.nextRecommendation = this.state.recommendationItems.shift();
      }
    } catch (error) {
      // TODO: do something with error
      this.state.nextRecommendation = null;
    }

    this.removeChild(this.elements.loading);

    if (!this.state.nextRecommendation) {
      this.observer.unobserve(this.elements.trigger);
    }

    this.state.isLoading = false;
  }
}
