import { isModernEnough } from "./helpers/browser-support.js";
import { initDidomiPreferences } from "./didomi.js";
import { initTracking } from "./tracking.js";
import { initResizeHandler } from "./iframe-resizer.js";
import "./custom-elements/index.js";

if (isModernEnough()) {
  initDidomiPreferences();
  initTracking();
  initResizeHandler();

  import("./rum-reporter.js")
    .then(({ initRumReporter }) => initRumReporter());
}
