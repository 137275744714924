import HTMLParsedElement from "../html-parsed-element.js";

export const TAG_NAME = "gen-share-button";
export class ShareButton extends HTMLParsedElement {
  parsedCallback() {
    const button = this.querySelector("button");

    if (!button) {
      return;
    }

    const title = button.getAttribute("data-share-title") || document.title;
    const url = button.getAttribute("data-share-url") || window.location.href;
    const shareData = { title, url };

    if ("canShare" in navigator && navigator.canShare(shareData)) {
      button.addEventListener("click", () => {
        navigator.share(shareData)
          .catch((error) => {
            if (error.name.includes("AbortError")) {
              return; // the user canceled the share operation
            }
          });
      });
    } else {
      button.remove();
    }
  }
}
