/**
 * @param {string} path
 * @returns {Promise<string | undefined>}
 */
const resolvePartialContent = async ({ path }) => {
  const searchParams = new URLSearchParams({ path });

  const response = await fetch(`/api/resolve-partial-content/?${searchParams.toString()}`);

  if (response.ok) {
    return await response.text();
  }
};

export default resolvePartialContent;
