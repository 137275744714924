import HTMLParsedElement from "../html-parsed-element.js";

/** @typedef {import('../../../../lib/@types/didomi-types.js').IDidomiObject} Didomi */

const TAG_NAME = "gen-embed-resource";

class Resource extends HTMLParsedElement {
  constructor() {
    super();

    this.checkConsent = this.checkConsent.bind(this);
    this.approveVendor = this.approveVendor.bind(this);
  }
  /**
   * @description Runs once when the element is parsed by the browser, is in charge of rendering the element based on props etc. Originates from the package https://www.npmjs.com/package/html-parsed-element
   * Modeled after https://github.com/BonnierNews/bnlo-jalla/blob/425a02f86c9da35e2670371ba83b22f4d38cb240/sources/js/custom-elements/resource.js#L6
   */
  parsedCallback() {
    this.overrideCmp = this.getAttribute("data-override-cmp");
    this.vendorId = this.getAttribute("data-vendor-id");
    this.placeholderText = this.getAttribute("data-placeholder-text");
    this.placeholderTextBlocked = this.getAttribute("data-placeholder-text-blocked");
    this.placeholderElement = this.querySelector("embed-resource__placeholder-text");
    this.approveVendorButton = this.querySelector("#approve-vendor");

    if (!this.vendorId) {
      throw new Error("Resource element must have a data-vendor attribute");
    }

    if (!this.iframe || !this.iframe.getAttribute("data-src")) {
      throw new Error("Resource element must have an iframe child element");
    }

    if (this.overrideCmp === "true") {
      this.activate();
    } else {
      // Ensure we wait for Didomi to load before calling Didomi-functions
      window.didomiOnReady = window.didomiOnReady || [];
      window.didomiOnReady.push(this.checkConsent);

      // Ensure to check for consent every time the consent change to check if we should activate or not
      window.didomiEventListeners = window.didomiEventListeners || [];
      window.didomiEventListeners.push({
        event: "consent.changed",
        listener: this.checkConsent,
      });
    }

    if (this.approveVendorButton) {
      this.approveVendorButton.addEventListener("click", () => window.didomiOnReady.push(this.approveVendor));
    }
  }

  get placeholder() {
    return this.placeholderElement && this.placeholderElement.textContent;
  }

  set placeholder(text) {
    if (this.placeholderElement) {
      this.placeholderElement.textContent = text;
    }
  }

  get iframe() {
    return this.querySelector("iframe");
  }

  /**
   *
   * @param {Didomi} Didomi
   */
  checkConsent(Didomi) {
    const hasConsent = Didomi.getUserConsentStatusForVendor(this.vendorId);

    if (hasConsent) {
      this.activate();
    } else {
      this.block();
    }
  }

  block() {
    this.removeAttribute("active");
    this.setAttribute("blocked", "");
    this.iframe.src = "about:blank";
    this.placeholder = this.placeholderTextBlocked;
  }

  /**
   * @description Runs when vendor is approved by Didomi/CMP. Loads the iframe.
   */
  activate() {
    this.setAttribute("active", "");
    this.removeAttribute("blocked");
    const iframe = this.iframe;
    const src = iframe.getAttribute("data-src");
    iframe.src = src;
  }

  // Automatically approve the vendor with all it's purposes
  /**
   *
   * @param {Didomi} Didomi
   */
  approveVendor(Didomi) {
    const {
      getVendors,
      openTransaction,
    } = Didomi;

    const allVendors = getVendors();

    const vendorItem = allVendors.find((i) => i.id === this.vendorId);

    if (vendorItem) {
      const transaction = openTransaction();

      transaction.enableVendor(vendorItem.id);
      transaction.enablePurposes(...vendorItem.purposeIds);
      transaction.commit();
    }
  }
}

export {
  TAG_NAME,
  Resource,
};
