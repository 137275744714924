/**
 * @description Resize iframe if iframe sends a message to parent page that contains a height-value. (Note: Some 3rd party embeds like facebook-posts will send a suitable height value. While others like youtube seem to default to height=150)
 * Copied from https://github.com/BonnierNews/bnlo-jalla/blob/master/sources/js/modules/iframe-resize-handler.js
 */
export const initResizeHandler = () => {
  /** @type {(event: MessageEvent) => void} */
  const messageHandler = (event) => {
    try {
      const data = getData(event);
      if (!data.height) {
        return;
      }

      /** @type {HTMLIFrameElement} */
      const iframe = document.querySelector(`[src^="${data.origin || event.origin}"]`);
      if (iframe) {
        iframe.height = data.height;
        iframe.classList.add("iframe-resized");
      }
    } catch (err) {
      // eslint-ignore-next-line no-empty
    }
  };

  if (window.addEventListener) {
    window.addEventListener("message", messageHandler, false);
  } else {
    // @ts-ignore
    window.attachEvent("onmessage", messageHandler);
  }
};

function getData(event) {
  if (typeof event.data === "string") {
    return JSON.parse(event.data);
  }
  return event.data;
}
