const TAG_NAME = "gen-sticky-tracker";

class StickyTracker extends HTMLElement {
  connectedCallback() {
    const style = window.getComputedStyle(this);
    if (style.position !== "sticky") {
      return;
    }
    this.lastTriggerPosition = undefined;
    this.observer = new IntersectionObserver(this.observerHandler.bind(this), {
      root: null,
      rootMargin: "-1px 1px -1px 1px",
      threshold: [ 1 ],
    });
    this.observer.observe(this);
    this.top = style.top;
    this.bottom = style.bottom;
  }

  observerHandler(entries) {
    const entry = entries[0];
    const y = entry.boundingClientRect.y;

    // eslint-disable-next-line no-nested-ternary
    const direction = this.lastTriggerPosition !== undefined ?
      (this.lastTriggerPosition > y ? "down" : "up")
      : "down";

    this.lastTriggerPosition = y;

    if (entry.intersectionRatio < 1) {
      if (this.top !== "auto" && direction === "down") {
        this.dataset.stuckTo = "top";
      }

      if (this.bottom !== "auto" && direction === "up") {
        this.dataset.stuckTo = "bottom";
      }
    } else {
      delete this.dataset.stuckTo;
    }
  }
}

export {
  TAG_NAME,
  StickyTracker,
};
